<template>
	<div class="header_bg mn-100">
		<header class="container">
			<Navbar logoColor=""></Navbar>
		</header>
		<div class="container">
			<div class="row search-box align-items-center">
				<div class="col-12  mx-auto text-center px-0">
					<p class="title_s">Dobrodošli</p>
					<h1 class="title_xxl my-4">
						Umrežavanje srpskih istraživača <br>širom sveta
					</h1>
					<router-link to="/istrazivaci">
						<button type="button" class="btn button_red-small mt-3" >
							Pretražite našu bazu
						</button>
					</router-link>
				</div>

			</div>
		</div>

		<div class="container">
			<div class="row">
				<p class="col-12 text_m text_400 " style="color: #fff">
					Copyright © 2021 - {{new Date().getFullYear()}} Serbian Researchers <span class="d-none d-lg-inline-block">| Sva prava zadržana.</span>
				</p>
			</div>
		</div>
		<Cookies></Cookies>
	</div>
</template>


<script>
import Navbar from '@/components/layout/Navbar'
import Cookies from "@/components/legal/Cookies";
export default{
	name: "Home",
	components: {
		Navbar,
		Cookies
	},
}

</script>
