<template>
  <div>

    <vue-cookie-accept-decline
        :ref="'legal-sr'"
        :elementId="'legal-sr'"
        :debug="false"
        :position="'bottom-center'"
        :type="'floating'"
        :disableDecline="true"
        :transitionName="'slideFromBottom'"
        :showPostponeButton="false"
        @status="cookieStatus"
        @clicked-accept="cookieClickedAccept"
        @clicked-decline="cookieClickedDecline"
        @clicked-postpone="cookieClickedPostpone"
        @removed-cookie="cookieRemovedCookie">

      <!-- Optional -->
      <div slot="postponeContent">
        &times;
      </div>

      <!-- Optional -->
      <div slot="message">
        Ovaj veb sajt koristi kolačiće radi unapređenja korisničkog iskustva, analitiku internet saobraćaja i omogućavanja njegovih funkcionalnosti.
        Nastavljanjem korišćenja veb sajta slažete se sa našom upotrebom kolačića u skladu sa Politikom kolačića.
        Više informacija o načinu upravljanja kolačićima (menjanje preferencija ili njihovo blokiranje) možete videti
        <router-link to="/cookie-policy" class="link">
          <u>ovde</u>
        </router-link>
      </div>

      <!-- Optional -->
      <div slot="declineContent">
        OPT OUT
      </div>

      <!-- Optional -->
      <div slot="acceptContent">
        U redu
      </div>
    </vue-cookie-accept-decline>

  </div>
</template>

<script>
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'

import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
export default {
  name: 'cookies',
  components:{
    VueCookieAcceptDecline
  },
  data () {
    return {
      status: null
    }
  },
  methods: {
    cookieStatus (status) {
      this.status = status
    },
    cookieClickedAccept () {
      this.status = 'accept'
    },
    cookieClickedDecline () {
      this.status = 'decline'
    },
    cookieClickedPostpone () {
      this.status = 'postpone'
    },
    cookieRemovedCookie () {
      this.status = null
      this.$refs.myPanel1.init()
    },
    removeCookie () {
      this.$refs.myPanel1.removeCookie()
    }
  },
  computed: {
    statusText () {
      return this.status || 'No cookie set'
    }
  }
}
</script>

<style >

.cookie__floating__buttons__button:first-child {
  max-width: 200px;
  margin-left: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  background-color: #2a51b5;
  border: 0;
}

#legal-sr {
  max-width: 95%;
}

@media (min-width: 992px) {

  .cookie__floating__content {
    max-height: 500px !important;
  }
}
</style>
